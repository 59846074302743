import Swiper, { Navigation } from 'swiper';

function CardsD1() {
    Swiper.use([Navigation]);

    $('[data-module="cardsD1"]').each(function () {
        const swiper = new Swiper($(this).find('[data-slider]')[0], {
            slidesPerView: 'auto',
            spaceBetween: 16,

            noSwipingSelector: 'a',

            breakpoints: {
                1024: {
                    spaceBetween: 24,
                },
            },

            navigation: {
                prevEl: $(this).find('.js-slider-prev')[0],
                nextEl: $(this).find('.js-slider-next')[0],
            },
        });
    });
}

export default CardsD1;
