/**
 * Nav Dropdowns Module
 *
 * This module handles the functionality of toggling dropdown menus
 * in the mobile navigation. It triggers on click or 'Enter' keypress events.
 *
 * @module navMob
 * @param {jQuery} $ - jQuery object for DOM manipulation
 * @author Dreamers of Day
 */

function navMob($) {
    // Selector for the dropdown toggle elements
    const selector = '.js-nav-drop';

    // Class added to elements when dropdown is active
    const activeClass = 'm-nav__toggle-wrapper--active';

    /**
     * Toggles the visibility of the dropdown content.
     *
     * This function toggles the active class on the clicked element, slides
     * the corresponding dropdown content, and manages additional classes for
     * child and parent elements to reflect the open/closed state.
     *
     * @param {HTMLElement} element - The clicked element that triggers the dropdown toggle
     */
    function toggleDropdown(element) {
        // Toggle the active class on the clicked element
        $(element)
            .toggleClass(activeClass)
            // Find the next sibling element with the dropdown content and slide it up/down
            .next('.m-nav__content')
            // Use slideToggle for smooth opening/closing animation
            .slideToggle(250);

        // Toggle the class on the child element to change the arrow icon
        $(element)
            .children('.js-plus-toggle')
            .toggleClass('a-plus-toggle--opened');

        // Toggle the active class on the parent navigation item
        $(element)
            .parent('.m-nav__item--level-0')
            .toggleClass('m-nav__item--level-0--active');
    }

    // Attach event listeners to the dropdown toggle elements
    $(selector).on('click keydown', function (event) {
        // Check if the event is a click or an Enter keypress
        if (
            event.type === 'click' ||
            (event.type === 'keydown' && event.key === 'Enter')
        ) {
            // If valid event, call toggleDropdown function with the clicked element
            toggleDropdown(this);
        }
    });
}

export default navMob;
