/*
 * Nav Dropdown
 *
 * runs the functionality of clicking the menu item with submenus
 */
function clickActionOutsideArea(e) {
    if (
        $(e.target).is($('a.menu-item-has-children')) === false &&
        $('a.menu-item-has-children').has(e.target).length === 0 &&
        $(e.target).is($('.m-nav-dropdown')) === false &&
        $('.m-nav-dropdown').has(e.target).length === 0
    ) {
        $('.m-nav-dropdown').removeClass('m-nav-dropdown--active');
    }
}

function navDropdown() {
    $('a.menu-item-has-children').on('click', function (e) {
        const isMobilePrimaryMenu = !!$(this).closest('.m-nav--primary-mobile')
            .length;
        if (isMobilePrimaryMenu) {
            return;
        }

        e.preventDefault();

        let sibling = $(this).siblings('.m-nav-dropdown');

        $('.m-nav-dropdown').not(sibling).removeClass('m-nav-dropdown--active');

        $(this)
            .siblings('.m-nav-dropdown')
            .toggleClass('m-nav-dropdown--active');
    });

    $(document).on('click', function (e) {
        if ($('.m-nav-dropdown').hasClass('m-nav-dropdown--active')) {
            clickActionOutsideArea(e);
        }
    });
}

export default navDropdown;
