import AOS from 'aos';

function accordionMobileInit() {
    // Base hooks for the accordion
    const hooks = {
        group: '[data-accordion-mobile]',
        content: '[data-accordion-mobile-content]',
        title: '[data-accordion-mobile-title]',
        track: '[data-accordion-mobile-track]',
    };

    // All used classed are declared here
    const classes = {
        active: 'is-active',
        trackClicked: 'rotate-180',
    };

    const maxMobileScreenWidth = 1360;

    // initAccordions();

    $(hooks.title)
        .click(toggleAccordion)
        .keydown(function (e) {
            if (e.keyCode === 13) {
                // 13 is the key code for Enter
                toggleAccordion.call(this, e);
            }
        });

    function toggleAccordion(e) {
        if (window.innerWidth < 1360) {
            const group = $(this).closest(hooks.group);
            const content = group.find(hooks.content);
            const track = group.find(hooks.track);

            group.toggleClass(classes.active);
            track.toggleClass(classes.trackClicked);

            // eslint-disable-next-line jquery/no-slide
            content.slideToggle(300);

            // refresh Animation on Scroll module Events
            setTimeout(function () {
                AOS.refresh();
            }, 300);
        }
    }

    // Opens the accordion if the browser after resize is wider than the 1024px
    // $(window).resize(function (e) {
    //     const hiddenContent = $(`${hooks.content}[style="display: none;"]`);

    //     if (window.innerWidth > maxMobileScreenWidth && hiddenContent) {
    //         hiddenContent.slideDown();
    //         $(hooks.track).removeClass(classes.trackClicked);
    //     }
    // });

    function initAccordions() {
        if (window.innerWidth < maxMobileScreenWidth) {
            $(hooks.content).slideUp();
            $(hooks.track).toggleClass(classes.trackClicked);

            // Set tabindex="0" for each title
            $(hooks.title).each(function () {
                $(this).attr('tabindex', '0');
            });
        }
    }
}

export default accordionMobileInit;
