import Swiper, { Navigation } from 'swiper';
function ColumnsA2() {
    Swiper.use([Navigation]);

    $('[data-module="columnsA2"]').each(function () {
        const module = $(this);

        const swiper = new Swiper(module.find('[data-slider]')[0], {
            slidesPerView: 'auto',
            spaceBetween: 16,

            lazy: true,

            breakpoints: {
                1024: {
                    spaceBetween: 24,
                },
            },

            navigation: {
                prevEl: module.find('.js-slider-prev')[0],
                nextEl: module.find('.js-slider-next')[0],
            },
        });
    });
}

export default ColumnsA2;
