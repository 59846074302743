function showAccordion() {
    // Define a common function to handle accordion toggling
    function toggleAccordion(e) {
        const target = e.target;
        // Avoid toggling if the target is a link
        if (target.closest('a')) {
            return;
        }

        e.preventDefault();

        // Get the nearest accordion group from the clicked or focused element
        const groupInner = $(this); // 'this' refers to the '.js-accordion-group-inner' clicked or focused
        const group = groupInner.closest('.js-accordion-group'); // Finds the nearest accordion group
        group.toggleClass('is-active');
        groupInner.find('.m-accordion__text').slideToggle('fast');
        groupInner.find('.m-accordion__button-wrapper').slideToggle('fast');
    }

    // Attach event handler for click
    $('.js-accordion-group-inner').click(toggleAccordion);

    // Attach event handler for keydown to respond to 'Enter' key
    $('.js-accordion-group-inner').keydown(function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            toggleAccordion.call(this, e); // Using call to ensure 'this' is correctly referenced
        }
    });
}

export default showAccordion;
