class EventsTypeA {
    constructor(block) {
        this.container = block;

        this._initProps();
        this._initElements();
        this._initStates();
        this._initListeners();
    }

    /**
     * All needed parameters of the block are set up here
     *
     * @private
     */
    _initProps() {
        // Main organism's class
        this.componentClass = 'o-filter-1';

        // Block Top offset
        this.blockOffsetTop = 70;

        // Data from backend
        // URL for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxUrl = eventsBlock.url;
        // Name of the action
        // eslint-disable-next-line no-undef
        this.ajaxAction = eventsBlock.action;
        // Nonce code for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxNonce = eventsBlock.nonce;

        // Whether block is shown currently or not
        this.isBlockOpened = false;

        // Current data of the filters form
        this.formData = {};

        // Are we currently waiting for AJAX response?
        this.isProcessingAjax = false;

        // Global Loader
        this.globalLoader = window.globalLoader;
    }

    /**
     * All DOM elements within the main Block which we are going to use are set up here
     *
     * @private
     */
    _initElements() {
        // Form Block Toggling button
        this.togglerButton = this.container.querySelector(
            `[data-role='block-toggler']`,
        );
        // Form
        this.filtersForm = this.container.querySelector(
            `[data-role='filters-form']`,
        );
        // Dates
        this.datesInputs = {
            date_from: this.container.querySelector(`input[name='date_from']`),
            date_to: this.container.querySelector(`input[name='date_to']`),
        };
        // Terms List
        this.termsList = this.container.querySelectorAll(
            `[data-role='terms-list']`,
        );
        // Form Clear Button
        this.formClearButton = this.container.querySelector(
            `[data-role='clear-form']`,
        );
        // Submit Button
        this.submitButton = this.container.querySelector(
            `[data-role='submit-form']`,
        );
        // The main container of posts
        this.filterList = this.container.querySelector(
            `[data-role='posts-list']`,
        );
        // Page Input
        this.pageInput = this.container.querySelector(`input[name='page']`);
        // Is Pagination input. Helps to distinguish pagination query from other queries
        this.paginationInput = this.container.querySelector(
            `[name='isPagination']`,
        );
        // Pagination Container
        this.paginationBlock = this.container.querySelector(
            `[data-role='pagination-block']`,
        );
        // Total posts count input
        this.totalPostsCountInput = this.container.querySelector(
            `input[name='totalPostsCount']`,
        );
    }

    /**
     * Set certain states for elements on class init
     *
     * @private
     */
    _initStates() {
        // Update the initial form data
        this._updateFormData();
    }

    /**
     * All events listeners are listed here
     *
     * @private
     */
    _initListeners() {
        // Form Submit button
        this.submitButton.addEventListener(
            'click',
            this._submitButtonHandler.bind(this),
        );
        // Clear Form button
        if (this.formClearButton) {
            this.formClearButton.addEventListener(
                'click',
                this._clearFormButtonHandler.bind(this),
            );
        }
        // Pagination
        if (this.paginationBlock) {
            this.paginationBlock.addEventListener(
                'click',
                this._paginationHandler.bind(this),
            );
        }
        // Filter Form Block Toggling
        document.addEventListener(
            'click',
            this._documentClicksHandler.bind(this),
        );
    }

    /**
     * Catches every click on a document and show/hide filters block depending on a target of the click
     *
     * @param event
     * @private
     */
    _documentClicksHandler(event) {
        // Click's target
        let target = event.target;

        // If it's a toggling button, toggle block and exit from the function
        if (this.togglerButton.contains(target)) {
            this._toggleFilterBlock(event);
            return;
        }

        // If click is inside filter form or filters form is currently closed - do nothing
        if (!this.isBlockOpened || this.filtersForm.contains(target)) {
            return;
        }

        // Otherwise - hide filters block
        this._hideFilterBlock();
    }

    /**
     * Hide/Show Filters Block
     * @param event
     * @private
     */
    _toggleFilterBlock(event) {
        event.preventDefault();

        if (this.isBlockOpened) {
            this._hideFilterBlock();
        } else {
            this._showFilterBlock();
        }
    }

    /**
     * Hiding Filters Block
     * @private
     */
    _hideFilterBlock() {
        jQuery(this.filtersForm).slideUp();
        const arrow = this.togglerButton.querySelector(
            '.o-events-a-1__filter-arrow',
        );
        if (arrow) {
            arrow.classList.remove('rotate-180');
        }

        this.isBlockOpened = false;
    }

    /**
     * Showing Filters Block
     * @private
     */
    _showFilterBlock() {
        jQuery(this.filtersForm).slideDown();
        const arrow = this.togglerButton.querySelector(
            '.o-events-a-1__filter-arrow',
        );
        if (arrow) {
            arrow.classList.add('rotate-180');
        }

        this.isBlockOpened = true;
    }

    /**
     * Form Submit button click event handler
     *
     * @param event
     * @private
     */
    _submitButtonHandler(event) {
        // Preventing default behaviour
        event.preventDefault();

        // On form submitting we need to see the first page
        this.pageInput.value = 1;
        // Sending AJAX request
        this._requestPosts();
    }

    /**
     * Collect the current form data and store them in the Class's parameter
     *
     * @private
     */
    _updateFormData() {
        let formData = new FormData(this.filtersForm);
        formData.append('action', this.ajaxAction);
        formData.append('_nonce', this.ajaxNonce);

        // For dealing with multiselect values we need to filter it
        const data = {};
        formData.forEach(function (value, key) {
            const prev = data[key];
            if (prev) {
                data[key] = `${prev}, ${value}`;
            } else {
                data[key] = value;
            }
        });

        this.formData = data;
    }

    /**
     * Sending AJAX request with parameters for getting posts list output
     *
     * @private
     */
    _requestPosts() {
        // Update current form data before sending it to backend
        this._updateFormData();

        // eslint-disable-next-line jquery/no-ajax
        $.ajax({
            type: 'POST',
            url: this.ajaxUrl,
            data: this.formData,
            beforeSend: () => {
                this.isProcessingAjax = true;
                // Show loader
                this._updateLoaderState();
            },
            success: (response) => {
                // New output from backend (posts list)
                let postsListOutput = response.data.postsOutput;
                if (!postsListOutput) {
                    return;
                }
                // Update the whole posts list container
                this.filterList.innerHTML = postsListOutput;

                // Updating the total posts count list
                if (
                    response.data.totalPostsCount !== undefined &&
                    this.totalPostsCountInput
                ) {
                    this.totalPostsCountInput.value = parseInt(
                        response.data.totalPostsCount,
                    );
                }

                if (!this.paginationBlock) {
                    return;
                }
                // Pagination Block
                let paginationBlockOutput = response.data.paginationBlockOutput;
                if (!paginationBlockOutput) {
                    return;
                }

                // Update the whole pagination block container
                this.paginationBlock.innerHTML = paginationBlockOutput;
            },
            // eslint-disable-next-line no-unused-vars
            complete: (xhr) => {
                this.isProcessingAjax = false;
                // Hide loader
                this._updateLoaderState(false);
                if (this.paginationInput) {
                    this.paginationInput.value = 0;
                }
                this._hideFilterBlock();
                // Scroll to the top of the block
                this._scrollToBlockTop();
            },
            error: (xhr) => {
                console.log('error:', xhr);
            },
        });
    }

    /**
     * Show Loader above the main filter block. show = true - show loader; show = false - hide loader
     *
     * @param show
     * @private
     */
    _updateLoaderState(show = true) {
        if (!this.globalLoader) {
            if (show) {
                this.container.classList.add(
                    'opacity-50',
                    'pointer-events-none',
                );
            } else {
                this.container.classList.remove(
                    'opacity-50',
                    'pointer-events-none',
                );
            }
        } else {
            if (show) {
                this.globalLoader.showLoader();
            } else {
                this.globalLoader.hideLoader();
            }
        }
    }

    /**
     * Pagination functionality
     *
     * @param event
     * @private
     */
    _paginationHandler(event) {
        event.preventDefault();

        const target = event.target;
        const button = target.closest(`[data-role='pagination-button']`);
        if (!button) {
            return;
        }

        const page = parseInt(button.getAttribute('data-value'));
        if (isNaN(page)) {
            return;
        }

        this.pageInput.value = page;
        if (this.paginationInput) {
            this.paginationInput.value = 1;
        }
        this._requestPosts();
    }

    /**
     * Clear All button functionality
     *
     * @param event
     * @private
     */
    _clearFormButtonHandler(event) {
        event.preventDefault();

        // Unselect terms
        if (this.termsList.length) {
            [...this.termsList].map((list) => {
                const inputs = list.querySelectorAll(
                    `input[type='checkbox'], input[type='radio']`,
                );
                [...inputs].map((input) => {
                    input.checked = false;
                    input.dispatchEvent(new Event('change'));
                });
            });
        }

        // Clear dates inputs
        for (const key in this.datesInputs) {
            if (this.datesInputs[key]) {
                this.datesInputs[key].value = '';
                this.datesInputs[key].dispatchEvent(new Event('change'));
            }
        }
    }

    /**
     * Smooth Scrolling to the top of the block. Better to use in mobile mode
     * @private
     */
    _scrollToBlockTop() {
        let bodyOffset = document.body.getBoundingClientRect().top;
        let blockOffset = this.container.getBoundingClientRect().top;

        let neededOffset = blockOffset - bodyOffset - this.blockOffsetTop;
        neededOffset = neededOffset > 0 ? neededOffset : 0;

        window.scrollTo({
            top: neededOffset,
            behavior: 'smooth',
        });
    }
}

function eventsTypeA() {
    const filterBlocks = document.querySelectorAll(
        `[data-role='events-type-a-1']`,
    );
    if (!filterBlocks.length) {
        return;
    }
    filterBlocks.forEach((block) => {
        new EventsTypeA(block);
    });
}

export default eventsTypeA;
