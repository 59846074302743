/*
 * External Links
 *
 * Open all external link in new tab
 */
import MicroModal from 'micromodal';
function externalLinks() {
    $('main a:not([target="_blank"])').each(function () {
        if (!$(this).attr('href')) {
            return;
        }

        if (checkExtrenalLink($(this).attr('href'))) {
            $(this).attr('target', '_blank');
        }
    });

    $('main .o-block').on('click', 'a', function (e) {
        if (
            checkExtrenalLink($(this).attr('href')) &&
            $('#modal-external-links').length > 0 &&
            !$(this).parents('.o-footer-1').length
        ) {
            e.preventDefault();
            MicroModal.show('modal-external-links');
            var extURL = $(this).attr('href');
            $('#external_link').attr('href', extURL);
        } else if (
            checkIntrenalLink($(this).attr('href')) &&
            $('#modal-internal-links').length > 0 &&
            !$(this).parents('.o-footer-1').length
        ) {
            e.preventDefault();
            MicroModal.show('modal-internal-links');
            var intURL = $(this).attr('href');
            $('#internal_link').attr('href', intURL);
        }
    });

    $('main').on('click', 'a:not([target="_blank"])', function (e) {
        if (checkExtrenalLink($(this).attr('href'))) {
            e.preventDefault();

            window.open($(this).attr('href'), '_blank');
        }
    });

    function addAriaLabelToLinks() {
        // Select all anchor tags that open in a new tab or window
        const links = document.querySelectorAll('a[target="_blank"]');

        links.forEach((link) => {
            // Define the linkText variable
            let linkText;

            // Check if link.textContent exists and is not just whitespace
            if (link.textContent && link.textContent.trim()) {
                // Format the linkText with the trimmed text content
                linkText = `${link.textContent.trim()}, opens a new window`;
            } else {
                // Set a default message if there is no text content
                linkText = 'Link opens in a new tab';
            }

            // Set the aria-label attribute only if it's missing
            if (!link.getAttribute('aria-label')) {
                link.setAttribute('aria-label', linkText);
            }
        });
    }

    // Call the function to add aria-label to links
    addAriaLabelToLinks();

    function checkExtrenalLink(href) {
        if (!href) {
            return false;
        }
        var href_split = href.split('/');

        if (
            (href_split[0] == 'http:' || href_split[0] == 'https:') &&
            href_split[2] != window.location.host
        ) {
            return true;
        } else {
            return false;
        }
    }

    function checkIntrenalLink(href) {
        if (!href) {
            return false;
        }
        var href_split = href.split('/');

        if (
            (href_split[0] == 'http:' || href_split[0] == 'https:') &&
            href_split[2] == window.location.host
        ) {
            return true;
        } else {
            return false;
        }
    }
}

export default externalLinks;
