import MicroModal from 'micromodal';

import { YoutubeEmbedVideos } from '../../../../global/js/util/youtubeVideo';

export default () => {
    /**
     * Save a modal instance while the Modal is open
     * Save a videoType instance while the Modal is open
     */
    let __modal = '';
    let __videoType = '';
    let __videoEmbedService = '';

    MicroModal.init({
        onShow: (modal) => {
            if (!isCurrentModule(modal)) {
                return;
            }
            openActions(modal);
        },
        onClose: (modal) => {
            if (!isCurrentModule(modal)) {
                return;
            }
            closeActions(modal);
        },
        disableScroll: true,
        awaitOpenAnimation: false,
        awaitCloseAnimation: true,
    });

    function isCurrentModule(modal) {
        return !!modal.closest('[data-module=modalVideoA1]');
    }

    /**
     * Provide actions when Modal is open
     * @param {object} modal
     * @return void
     * */
    function openActions(modal) {
        __modal = modal;
        __videoType = getVideoType(modal);
        __videoEmbedService = getVideoEmbedService(modal);

        document.body.classList.add('l-body--modal-open');

        document.addEventListener('lazyloaded', function (e) {
            if (__modal && __modal.contains(e.target)) {
                if ('vimeo' === __videoEmbedService) {
                    VimeoVideo.loadAPI();
                }
            }
        });

        if ('file' === __videoType) {
            FileVideo.playVideo();
        } else {
            if ('youtube' === __videoEmbedService) {
                YouTube.playVideo();

                if (YouTube.iframe) {
                    // Solution for the most browsers
                    YouTube.iframe.blur();

                    // Solution for Safari
                    let input = modal.querySelector(
                        `[data-role='focused-element']`,
                    );
                    if (!input) {
                        input = document.createElement('input');
                        input.setAttribute('type', 'hidden');
                        input.dataset.role = 'focused-element';
                        input.classList.add('!opacity-0');
                    }
                    YouTube.iframe.insertAdjacentElement('beforebegin', input);
                    input.focus();
                }
            }
        }
    }

    /**
     * Provide actions when Modal is closed
     * @param {object} modal
     * @return void
     * */
    function closeActions(modal) {
        document.body.classList.remove('l-body--modal-open');

        if ('file' === __videoType) {
            FileVideo.stopVideo();
        } else {
            if ('vimeo' === __videoEmbedService) {
                VimeoVideo.stopVideo();
            } else if ('youtube' === __videoEmbedService) {
                YouTube.pauseVideo();

                if (YouTube.iframe) {
                    // Solution for Safari
                    const input = modal.querySelector(
                        `[data-role='focused-element']`,
                    );
                    if (input) {
                        input.remove();
                    }
                }
            }
        }

        __modal = '';
        __videoType = '';
        __videoEmbedService = '';
    }

    /**
     * Get type of the video according to 01-atom video [data-video-type]
     * @param {object} modal
     * @return string|boolean
     * */
    function getVideoType(modal) {
        const video = modal.querySelector('.a-video');

        if (null === video) {
            return false;
        }
        if (video.hasAttribute('data-video-type')) {
            return video.getAttribute('data-video-type');
        }
        return false;
    }

    /**
     * Get type of the video embed service according to 01-atom video [data-video-embed-service]
     * @param {object} modal
     * @return string|boolean
     * */
    function getVideoEmbedService(modal) {
        const video = modal.querySelector('.a-video');
        if (null === video) {
            return false;
        }
        if (video.hasAttribute('data-video-embed-service')) {
            return video.getAttribute('data-video-embed-service');
        }
        return false;
    }

    /**
     * Functions for Vimeo Video
     * @param {object, null} iframe
     * @param {object, null} player
     * @param {boolean} libraryLoaded
     * */
    let VimeoVideo = {
        /** Save the instances while the Modal is open */
        iframe: null,

        /** Flag - Vimeo API is Loaded */
        libraryLoaded: false,

        onReadyCallback: function () {
            VimeoVideo.libraryLoaded = true;
            VimeoVideo.playVideo();
        },
        loadAPI: function () {
            let script = document.createElement('script');
            script.src = 'https://player.vimeo.com/api/player.js';
            script.onload = this.onReadyCallback;
            document.head.append(script);
        },
        playVideo: function () {
            this.iframe = __modal.querySelector('iframe');
            this.iframe.setAttribute(
                'src',
                this.iframe.getAttribute('data-src'),
            );

            if (!this.iframe) {
                return;
            }

            /** Load Vimeo API */
            if (!this.libraryLoaded) {
                this.loadAPI();
                return;
            }

            /** Play video */
            // eslint-disable-next-line no-undef
            this.player = new Vimeo.Player(this.iframe);
            this.player.setCurrentTime(0.01);
            this.player.play();
        },
        stopVideo: function () {
            this.iframe = __modal.querySelector('iframe');

            if (!this.iframe) {
                return;
            }

            /** Load Vimeo API */
            if (!this.libraryLoaded) {
                return;
            }
            // eslint-disable-next-line no-undef
            this.player = new Vimeo.Player(this.iframe);
            this.player.pause();
        },
    };

    /**
     * Functions for File Video
     * @param {object, null} video
     * */
    let FileVideo = {
        /** Save the instances while the Modal is open */
        video: null,

        playVideo: function () {
            this.video = __modal.querySelector('video');

            if (!this.video) {
                return;
            }

            this.video.play();
        },
        stopVideo: function () {
            if (!this.video) {
                return;
            }

            this.video.pause();

            this.video = null;
        },
    };

    /**
     * Functions for Youtube Video.
     * @param {HTMLElement} embed
     * @param {function} playVideo
     * @param {function} pauseVideo
     * */
    let YouTube = {
        embed: null,
        iframe: null,
        playVideo: function () {
            this.embed = __modal.querySelector(`[data-role='youtube-embed']`);
            YoutubeEmbedVideos.getInstance().then((instance) => {
                instance
                    .getVideo(this.embed)
                    .then((video) => {
                        this.iframe = this.embed.querySelector('iframe');
                        video.play();
                    })
                    .catch((error) => {
                        console.warn(`Youtube Embed Videos: ${error}`);
                    })
                    .catch((error) => {
                        console.warn(`Youtube Embed Videos: ${error}`);
                    });
            });
        },
        pauseVideo: function () {
            this.embed = __modal.querySelector(`[data-role='youtube-embed']`);
            YoutubeEmbedVideos.getInstance().then((instance) => {
                instance
                    .getVideo(this.embed)
                    .then((video) => {
                        video.pause();
                    })
                    .catch((error) => {
                        console.warn(`Youtube Embed Videos: ${error}`);
                    })
                    .catch((error) => {
                        console.warn(`Youtube Embed Videos: ${error}`);
                    });
            });
        },
        // getIframe(): function() {
        //
        // }
    };
};
