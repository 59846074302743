import Swiper, { Navigation } from 'swiper';

function CardsA3() {
    Swiper.use([Navigation]);

    // Function to check URL parameters
    // Function to check URL parameters
    function getUrlParameter(name) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(window.location.href);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    // Check if the "show-marketplace-slide" parameter is present (regardless of its value)
    var showMarketplaceSlide = getUrlParameter('slide-marketplace');
    if (showMarketplaceSlide !== null) {
        // Show the element with class ".slide-marketplace"
        $('.slide-marketplace').show();
    }

    // Check if the "show-marketplace-slide" parameter is present (regardless of its value)
    var showPicHiv = getUrlParameter('slide-partnership-in-care-pic-hiv---snp');
    if (showPicHiv !== null) {
        // Show the element with class ".slide-marketplace"
        $('.slide-partnership-in-care-pic-hiv---snp').show();
    }

    // Check if the "show-medicaid-slide" parameter is present (regardless of its value)
    var showMedicaidSlide = getUrlParameter('slide-medicaid');
    if (showMedicaidSlide !== null) {
        // Show the element with class ".slide-medicaid"
        $('.slide-medicaid').show();
    }

    // Check if the "slide-managed-long-term-care" parameter is present (regardless of its value)
    var slideManagedLongTermCare = getUrlParameter(
        'slide-managed-long-term-care',
    );
    if (slideManagedLongTermCare !== null) {
        // Show the element with class ".slide-managed-long-term-care"
        $('.slide-managed-long-term-care').show();
    }

    // Check if the "slide-medicare" parameter is present (regardless of its value)
    var slideMedicare = getUrlParameter('slide-medicare');
    if (slideMedicare !== null) {
        // Show the element with class ".slide-medicare"
        $('.slide-medicare').show();
    }

    // Check if the "slide-child-health-plus" parameter is present (regardless of its value)
    var slideChildHealthPlus = getUrlParameter('slide-child-health-plus');
    if (slideChildHealthPlus !== null) {
        // Show the element with class ".slide-child-health-plus"
        $('.slide-child').show();
    }

    // Check if the "slide-child-essential" parameter is present (regardless of its value)
    var slideChildEssential = getUrlParameter('slide-child-essential');
    if (slideChildEssential !== null) {
        // Show the element with class ".slide-child-essential"
        $('.slide-child').show();
    }

    // Check if the "slide-essential" parameter is present (regardless of its value)
    var slideEssential = getUrlParameter('slide-essential');
    if (slideEssential !== null) {
        // Show the element with class ".slide-essential"
        $('.slide-essential').show();
    }

    // Select all swiper-slide elements without inline style of display: block
    const hiddenSlides = document.querySelectorAll(
        '.swiper-slide:not([style*="display: block"])',
    );

    // Remove the selected elements from the DOM
    hiddenSlides.forEach((slide) => {
        slide.remove();
    });

    $('[data-module="cardsA3"]').each(function () {
        const module = $(this);

        const swiper = new Swiper(module.find('[data-slider]')[0], {
            slidesPerView: 'auto',
            spaceBetween: 16,

            lazy: true,

            breakpoints: {
                1024: {
                    spaceBetween: 24,
                },
            },

            navigation: {
                prevEl: module.find('.js-slider-prev')[0],
                nextEl: module.find('.js-slider-next')[0],
            },
        });

        swiper.on('progress', function (e, progress) {
            if (progress <= 0) {
                module
                    .find('.js-slider-prev')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-slider-prev')
                    .removeClass('a-btn-pagination--disabled');
            }

            if (progress >= 1) {
                module
                    .find('.js-slider-next')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-slider-next')
                    .removeClass('a-btn-pagination--disabled');
            }
        });

        if (swiper.isBeginning) {
            module
                .find('.js-slider-prev')
                .addClass('a-btn-pagination--disabled');
        }

        if (swiper.isEnd) {
            module
                .find('.js-slider-next')
                .addClass('a-btn-pagination--disabled');
        }
    });

    $('.o-cards-a-3__loader').fadeOut(500);
}

export default CardsA3;
