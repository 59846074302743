/**
 * Slide Out Module
 *
 * This module manages the behavior of menu items and tabbed content
 * within a slide-out interface. The actual opening and closing of the
 * slide-out is handled separately in hamburger.js.
 *
 * @module slideOut
 * @author Dreamers of Day
 */

const menuItem = '.js-menu-item'; // Selector for the menu item elements
const activeMenuClass = 'm-slide-out__nav-menu--active'; // Class for active menu items

/**
 * Initializes the slide-out functionality.
 *
 * This function handles the hover state of menu items and manages the
 * display of tabbed content within the slide-out. It adds and removes
 * relevant classes based on user interactions.
 */
export function slideOut() {
    // Retrieve the active tab from localStorage, or default to the first tab's target ID
    const activeTabId =
        localStorage.getItem('activeTab') ||
        $('.js-tab-button').first().data('target');

    // Attach hover event listeners to menu items
    $(menuItem).hover(
        function () {
            // On hover, remove the active class from all menu items
            $(menuItem).removeClass(activeMenuClass);

            // Add the active class to the currently hovered menu item
            $(this).addClass(activeMenuClass);
        },
        () => {
            // On hover out, remove the active class from all menu items
            $(menuItem).removeClass(activeMenuClass);
        },
    );

    // Cache the jQuery objects for tab buttons and tab content sections
    const tabButtons = $('.js-tab-button');
    const contents = $('.m-slide-out__tab-content');

    /**
     * Activates the specified tab by its target ID.
     *
     * @param {string} targetId - The ID of the tab content to activate.
     */
    const activateTab = (targetId) => {
        if (!targetId) {
            return;
        } // Exit if no targetId is provided

        // Remove the active class from all tab buttons and then add it to the target tab button
        tabButtons.removeClass('m-slide-out__tab-button--active');
        tabButtons
            .filter(`[data-target="${targetId}"]`)
            .addClass('m-slide-out__tab-button--active');

        // Hide all tab content sections, then show the one corresponding to the target ID
        contents
            .removeClass('m-slide-out__tab-content--active')
            .addClass('hidden');
        contents
            .filter(`#${targetId}`)
            .removeClass('hidden')
            .addClass('m-slide-out__tab-content--active');
    };

    // Attach click event listeners to tab buttons
    tabButtons.on('click', function () {
        // On click, activate the clicked tab and store its target ID in localStorage
        const targetId = $(this).data('target');
        activateTab(targetId);
        localStorage.setItem('activeTab', targetId);
    });

    // Activate the tab based on the stored activeTabId or default to the first tab
    activateTab(activeTabId);
}
