import Swiper, { Navigation } from 'swiper';

function CardsA1() {
    Swiper.use([Navigation]);

    $('[data-module="cardsA1"]').each(function () {
        const module = $(this);

        const swiper = new Swiper(module.find('[data-slider]')[0], {
            slidesPerView: 'auto',
            spaceBetween: 16,

            lazy: true,

            breakpoints: {
                1024: {
                    spaceBetween: 24,
                },
            },

            navigation: {
                prevEl: module.find('.js-slider-prev')[0],
                nextEl: module.find('.js-slider-next')[0],
            },

            on: {
                init: function (e) {
                    equalizeCardsContentHeight(e);
                },
                resize: function (e) {
                    equalizeCardsContentHeight(e);
                },
            },
        });

        swiper.on('progress', function (e, progress) {
            if (progress <= 0) {
                module
                    .find('.js-slider-prev')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-slider-prev')
                    .removeClass('a-btn-pagination--disabled');
            }

            if (progress >= 1) {
                module
                    .find('.js-slider-next')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-slider-next')
                    .removeClass('a-btn-pagination--disabled');
            }
        });

        if (swiper.isBeginning) {
            module
                .find('.js-slider-prev')
                .addClass('a-btn-pagination--disabled');
        }

        if (swiper.isEnd) {
            module
                .find('.js-slider-next')
                .addClass('a-btn-pagination--disabled');
        }

        /**
         * Make content-cards height the same for all slides
         **/
        function equalizeCardsContentHeight(e) {
            const sliderContents = e.wrapperEl.querySelectorAll(
                '.m-card-image-text-a__content-wrapper',
            );

            let maxHeight = 0;
            sliderContents.forEach((slide) => {
                const slideHeight = parseInt(getComputedStyle(slide).height);
                if (maxHeight < slideHeight) {
                    maxHeight = slideHeight;
                }
            });

            sliderContents.forEach((slide) => {
                slide.style.minHeight = `${maxHeight}px`;
            });
        }
    });
}

export default CardsA1;
