import Swiper, { Navigation } from 'swiper';

function CardsA2() {
    Swiper.use([Navigation]);

    $('[data-module="cardsA2"]').each(function () {
        const module = $(this);

        const swiper = new Swiper(module.find('[data-slider]')[0], {
            slidesPerView: 'auto',
            spaceBetween: 16,

            lazy: true,

            breakpoints: {
                1024: {
                    spaceBetween: 24,
                },
            },

            navigation: {
                prevEl: module.find('.js-slider-prev')[0],
                nextEl: module.find('.js-slider-next')[0],
            },
        });

        swiper.on('progress', function (e, progress) {
            if (progress <= 0) {
                module
                    .find('.js-slider-prev')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-slider-prev')
                    .removeClass('a-btn-pagination--disabled');
            }

            if (progress >= 1) {
                module
                    .find('.js-slider-next')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-slider-next')
                    .removeClass('a-btn-pagination--disabled');
            }
        });

        if (swiper.isBeginning) {
            module
                .find('.js-slider-prev')
                .addClass('a-btn-pagination--disabled');
        }

        if (swiper.isEnd) {
            module
                .find('.js-slider-next')
                .addClass('a-btn-pagination--disabled');
        }
    });
}

export default CardsA2;
