function footerLinks() {
    // CSS classes of Accordion items.

    const selector = {
        group: '.js-footer_group--accordion',
        heading: '.js-footer_heading--accordion',
        text: '.js-footer_text--accordion',
        track: '.js-footer_track--accordion',
    };

    // Active state classes.
    const state = {
        groupActive: 'is-active',
        trackActive: 'rotate-90',
    };

    /**
     * Toggles the active class, rotates the control track, and slides the text element up or down.
     * @function
     * @param {Event} e - The click event.
     */
    const toggleAccordion = (e) => {
        const group = e.target.closest(selector.group);
        const text = group.querySelector(selector.text);
        const track = group.querySelector(selector.track);
        const trackFirstLine = track.querySelector('span');

        group.classList.toggle(state.groupActive);
        trackFirstLine.classList.toggle(state.trackActive);
        $(text).slideToggle(300); // jQuery is used here, hence the eslint-disable-next-line comment.
    };

    /**
     * Adds the toggleAccordion function as a click event listener to each heading element.
     */
    document.querySelectorAll(selector.heading).forEach((heading) => {
        heading.addEventListener('click', toggleAccordion);
    });
}

export default footerLinks;
