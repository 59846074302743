/**
 * plugin Ajax Load More
 */
function almLoadMore() {
    const containers = $(
        '[data-module="almLoadMore"] button.alm-load-more-btn',
    );

    // Class that appears when AJAX is being sending
    const loadingClass = 'alm-loading';
    // Interval of class checking in milliseconds
    const interval = 100;

    $(containers).each(function () {
        // Interval variable
        let checkingInterval = null;

        const button = $(this);
        //
        button.addClass(
            'a-btn a-btn--style-open-type-02 relative overflow-hidden z-[1]',
        );
        // change button content
        const text = button.text();
        let btnInner = `<span class="a-btn__label relative block z-[2]" data-text="${text}">
                            <span class="a-btn__label-inner relative block">${text}</span>
                        </span>
                        <span class="a-btn__bg absolute inset-0 z-[-1]"></span>`;
        button.empty();
        button.append(btnInner);

        // Showing custom animation on loading
        button.click(() => {
            // Strange selector because if there are more than 1 ALM button their ids values have a certain postfix,
            // for example: 'ajax-load-more-2', 'ajax-load-more-3' etc.
            // Did not want to use class for selector since it can be overwritten perhaps.
            const container = $(this).closest("[id^='ajax-load-more']");
            if (!container || window.globalLoader === undefined) {
                return;
            }

            // Showing custom animation
            window.globalLoader.showLoader();

            // Starting to check loading class of the container block
            checkingInterval = setInterval(() => {
                listeningAlmRequest(container);
            }, interval);
        });

        // Checking the loading class for the passed container element
        // If it disappears hiding loader and removing interval function
        function listeningAlmRequest(container) {
            if (!container || !container.hasClass(loadingClass)) {
                // Hiding custom loading animation
                window.globalLoader.hideLoader();
                // Removing interval function in order to stop checking
                clearInterval(checkingInterval);
            }
        }
    });
}

export default almLoadMore;
