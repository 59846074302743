import Swiper, { Navigation, Pagination } from 'swiper';

function imagesB1() {
    Swiper.use([Navigation, Pagination]);

    $('[data-module="imagesB1"]').each(function () {
        const module = $(this);
        const swiper = new Swiper(module.find('[data-slider]')[0], {
            speed: 400,
            loop: true,
            slidesPerView: 1,

            navigation: {
                prevEl: module.find('.js-slider-prev')[0],
                nextEl: module.find('.js-slider-next')[0],
            },

            pagination: {
                el: module.find('.js-slider-progressbar')[0],
                type: 'progressbar',
                progressbarFillClass:
                    'o-block-blueprint-1__slider-progressbar-full block w-full h-full origin-left bg-brand-color-1',
            },
        });

        swiper.on('slideChange', function (e) {
            module.find('.js-slider-progress').text(e.realIndex + 1);
        });
    });
}

export default imagesB1;
