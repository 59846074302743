function tableA1() {
    $('[data-module="tableA1"]').each(function () {
        const module = $(this);
        const column = module.find('[data-column]');
        const columns_scroll = module.find('[data-columns-scroll]');

        // Sync Cell Heights

        function sync_heights() {
            const cell = module.find('[data-cell]');

            cell.css('minHeight', '');

            var items_heights = {};

            cell.each(function () {
                var item_index = $(this).data('cell');
                var item_height = $(this).outerHeight(true);

                if (
                    !Object.prototype.hasOwnProperty.call(
                        items_heights,
                        item_index,
                    )
                ) {
                    items_heights[item_index] = [];
                }

                items_heights[item_index].push(item_height);
            });

            $.each(items_heights, function (index, value) {
                var item_largest_height = 0;

                for (var i = 0; i < value.length; i++) {
                    if (value[i] > item_largest_height) {
                        item_largest_height = value[i];
                    }
                }

                module
                    .find('[data-cell="' + index + '"]')
                    .css('minHeight', item_largest_height);
            });
        }

        sync_heights();

        // END Sync Cell Heights

        // Sync Header heights and set offsets

        function sync_header_heights() {
            const header = module.find('[data-header]');

            if (header.length) {
                header.css('minHeight', '');

                var header_min_height = 0;
                var header_image_height = header.find('[data-image]').height();

                header.each(function () {
                    var item_height = $(this).outerHeight(true);

                    if (item_height > header_min_height) {
                        header_min_height = item_height;
                    }
                });

                header.css('minHeight', header_min_height);
            }
        }

        sync_header_heights();

        // END Sync Header heights and set offsets

        // Check if columns has scroll

        function columns_oveflow_check() {
            var columns_scroll_width = columns_scroll.width();
            var sum_width_columns = 0;

            column.each(function () {
                sum_width_columns += $(this).width();
            });

            if (Math.floor(sum_width_columns) > columns_scroll_width) {
                module.find('[data-controls]').show();
            } else {
                module.find('[data-controls]').hide();
            }
        }

        columns_oveflow_check();

        // END Check if columns has scroll

        // Check Scroll Button State

        function check_scroll_button_state() {
            var columns_width = column.length * column.width();
            var container_offset =
                ($(window).width() -
                    columns_scroll.closest('.l-container').outerWidth(true)) /
                2;

            var max_scroll_offset =
                columns_width - columns_scroll.width() + container_offset;

            if (columns_scroll.scrollLeft() >= max_scroll_offset) {
                module
                    .find('.js-scroll-right')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-scroll-right')
                    .removeClass('a-btn-pagination--disabled');
            }

            if (columns_scroll.scrollLeft() < 1) {
                module
                    .find('.js-scroll-left')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-scroll-left')
                    .removeClass('a-btn-pagination--disabled');
            }
        }

        check_scroll_button_state();

        columns_scroll.on('scroll', check_scroll_button_state);

        // END Check Scroll Button State

        // Scroll Buttons

        function get_scroll_offset(direction = 'right') {
            var scroll_pos = 0;
            var columns_scroll_pos = 0;
            var column_width = column.width();

            if (direction == 'right') {
                columns_scroll_pos = columns_scroll.scrollLeft() + 1;

                scroll_pos =
                    Math.ceil(columns_scroll_pos / column_width) * column_width;
            } else if (direction == 'left') {
                columns_scroll_pos = columns_scroll.scrollLeft() - 1;

                scroll_pos =
                    Math.floor(columns_scroll_pos / column_width) *
                    column_width;
            }

            return scroll_pos;
        }

        module.find('.js-scroll-right').click(function () {
            columns_scroll.animate(
                { scrollLeft: get_scroll_offset('right') },
                150,
            );
        });

        module.find('.js-scroll-left').click(function () {
            columns_scroll.animate(
                { scrollLeft: get_scroll_offset('left') },
                150,
            );
        });

        // END Scroll Buttons

        $(window).on('resize', function () {
            sync_heights();
            sync_header_heights();
            columns_oveflow_check();
            check_scroll_button_state();
        });
    });
}

export default tableA1;
