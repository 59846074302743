/**
 * Class of Dropdown-C-1 molecule
 */

class DropdownC {
    // The Main container of the block
    block;
    // Classes that makes dropdown visible
    _dropdownOpenedClasses = 'm-dropdown-c-1--opened';
    // Classes that are needed for hiding a dropdown
    _dropdownClosedClasses = 'm-dropdown-c-1--closed';

    constructor(block) {
        this.container = block;

        this._initElements();
        this._initListeners();
    }

    /**
     * Initializing all the needed elements of the class
     */
    _initElements() {
        this.button = this.container.querySelector(
            `[data-role='dropdown-button']`,
        );
        this.dropdownList = this.container.querySelector(
            `[data-role='dropdown-list']`,
        );
    }

    /**
     * Adding event Listeners
     */
    _initListeners() {
        document.addEventListener(
            'click',
            this._documentClicksHandler.bind(this),
        );
    }

    /**
     * Function that control opened/closed states of a dropdown on documents clicks
     *
     * @param event
     */
    _documentClicksHandler(event) {
        let button = event.target.closest(`[data-role='dropdown-button']`);
        if (button !== this.button) {
            this._hideDropdown();
            return;
        }

        if (this.container.classList.contains('m-dropdown-c-1--closed')) {
            this._showDropdown();
        } else {
            this._hideDropdown();
        }
    }

    /**
     * Show Dropdown
     */
    _showDropdown() {
        this.container.classList.add(this._dropdownOpenedClasses);
        this.container.classList.remove(this._dropdownClosedClasses);
        jQuery(this.dropdownList).slideDown(300);
        this.button
            .querySelector(`[data-role='dropdown-arrow']`)
            .classList.add('rotate-180');
    }

    /**
     * Hide Dropdown
     */
    _hideDropdown() {
        this.container.classList.remove(this._dropdownOpenedClasses);
        this.container.classList.add(this._dropdownClosedClasses);
        jQuery(this.dropdownList).slideUp(300);
        this.button
            .querySelector(`[data-role='dropdown-arrow']`)
            .classList.remove('rotate-180');
    }
}

function dropdownC() {
    const dropdowns = document.querySelectorAll(`[data-role='dropdown-c-1']`);
    [...dropdowns].map((dropdown) => {
        new DropdownC(dropdown);
    });
}

export default dropdownC;
