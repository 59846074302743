import marquee from 'vanilla-marquee';

export default () => {
    const marqueeEl = document.getElementById('marquee');
    const speed = marqueeEl.dataset.speed || 100;
    new marquee(marqueeEl, {
        speed: speed,
        duplicated: true,
        gap: 100,
    });
};
