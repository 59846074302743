function showAccordionB() {
    // Define a common function to handle accordion toggling
    function toggleAccordion(e) {
        e.preventDefault();

        const parent = $(this).closest('.m-accordion-b');
        parent.toggleClass('is-active');
        parent.find('.m-accordion-b__group').slideToggle('fast');

        const btnToggleText = parent.find('.m-accordion-b__track-text');
        const btnText = btnToggleText.text();
        // Swap text
        btnToggleText.text(btnToggleText.attr('data-alt-text'));
        btnToggleText.attr('data-alt-text', btnText);
    }

    // Attach click event handler
    $('.js-accordion-b').click(toggleAccordion);

    // Attach keydown event handler to respond to 'Enter' key
    $('.js-accordion-b').keydown(function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            toggleAccordion.call(this, e); // Ensure 'this' context is passed correctly
        }
    });
}

export default showAccordionB;
