import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function sliderBadgesB() {
    Swiper.use([Autoplay, Navigation, Pagination]);

    // Ensuring we don't show buttons if there is just 1 entry outputted
    if ($('.o-badges-b-1__stories-slide-item ').length <= 1) {
        $('.o-badges-b-1__stories-slider-wrapper').addClass(
            'o-badges-b-1__stories-slider-wrapper--disabled',
        );
    } else {
        $('.o-badges-b-1__stories-slider-wrapper').removeClass(
            'o-badges-b-1__stories-slider-wrapper--disabled',
        );

        new Swiper('.js-slider-badges-b', {
            loop: true,
            speed: 5000,
            simulateTouch: false,
            allowTouchMove: false,
            autoplay: {
                delay: 0,
            },
            slidesPerView: 2,
            spaceBetween: 50,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 60,
                },
            },
        });
    }
}

export default sliderBadgesB;
