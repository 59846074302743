function faqExpand() {
    const selectors = {
        textWrapper: '.js-text-wrapper',
        fullContent: '.full-content',
        trimmedContent: '.trimmed-content',
        showMoreBtn: '.js-show-more',
    };

    document.querySelectorAll(selectors.showMoreBtn).forEach((button) => {
        button.addEventListener('click', function () {
            let wrapper = this.previousElementSibling;
            let fullContent = wrapper.querySelector(selectors.fullContent);
            let trimmedContent = wrapper.querySelector(
                selectors.trimmedContent,
            );
            if (fullContent.classList.contains('hidden')) {
                fullContent.classList.remove('hidden');
                trimmedContent.classList.add('hidden');
                this.textContent = 'LESS';
            } else {
                fullContent.classList.add('hidden');
                trimmedContent.classList.remove('hidden');
                this.textContent = 'MORE';
            }
        });
    });
}

export default faqExpand;
