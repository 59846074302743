function fixAccessibility() {
    wpToolbarIssues();
}

/**
 * Issue: Content is not within a landmark element
 * Explanation: We should avoid having not a HTML5 sectioning element without a role attribute.
 * Therefore, we need to add a certain role attribute value to the WP toolbar element
 * Element: #wpadminbar
 */
function wpToolbarIssues() {
    const wpToolbar = document.getElementById('wpadminbar');
    if (wpToolbar) {
        wpToolbar.setAttribute('role', 'contentinfo');
    }
}

export default fixAccessibility;
