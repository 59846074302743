import MicroModal from 'micromodal';

export default () => {
    const modal = document.querySelector('[data-module="modal"]');

    if (!modal) {
        return;
    }

    MicroModal.init({
        onShow: () => $('body').addClass('l-body--modal-open'),
        onClose: () => $('body').removeClass('l-body--modal-open'),
        disableScroll: true,
        awaitOpenAnimation: false,
        awaitCloseAnimation: true,
    });
};
