/*
 * Headroom
 *
 * Calling the Headroom.js object
 */
/* eslint-disable */
let Headroom = require('headroom.js');

function header() {
    // Grab an element
    let header = document.querySelector('#header');

    // Construct an instance of Headroom, passing the element
    let headroom = new Headroom(header, {
        offset: 40,
        tolerance: {
            up: 15,
            down: 25,
        },
        classes: {
            // when element is initialised
            initial: 'l-headroom',
            // when scrolling up
            pinned: 'l-headroom--pinned',
            // when scrolling down
            unpinned: 'l-headroom--unpinned',
            // when above offset
            top: 'l-headroom--top',
            // when below offset
            notTop: 'l-headroom--not-top',
            // when at bottom of scoll area
            bottom: 'l-headroom--bottom',
            // when not at bottom of scroll area
            notBottom: 'l-headroom--not-bottom',
        },
    });

    // Initialise
    headroom.init();

    // Check if the body element has the "l-body--with-banner" class. This comes from the site options
    if ($('body').hasClass('l-body--with-banner')) {
        // Check if the banner_closed cookie is not set
        if (
            !document.cookie
                .split(';')
                .some((item) => item.trim().startsWith('banner_closed='))
        ) {
            // If the cookie is not set, remove the "hidden" class and slide down the banner to show it
            $('.o-header-1__header-top-banner-wrapper')
                .removeClass('hidden')
                .slideDown();
            // Add the "l-body--with-banner" class to the .l-body element
            $('.l-body').addClass('l-body--with-banner');
        } else {
            $('.l-body').removeClass('l-body--with-banner');
        }

        $('.o-header-top-banner__close-btn').on('click', function (e) {
            e.preventDefault();
            $('.o-header-1__header-top-banner-wrapper').slideUp();
            // Remove the "l-body--with-banner" class from the .l-body element
            $('.l-body').removeClass('l-body--with-banner');
            // Set a cookie to prevent banner from reopening for 2 weeks
            document.cookie =
                'banner_closed=true; max-age=' + 60 * 60 * 24 * 14 + '; path=/';
        });
    }
}

export default header;
